import { registerApplication, start } from "single-spa";

import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

import "import-map-overrides";

import "systemjs/s.js";
import "systemjs/dist/extras/amd.js";

import "zone.js"; // for Angular

import "jquery"; // for Bootstrap
import "bootstrap";

const configFileUrl = "/ui/assets/data/MerchantWebApplicationClientConfig.json";

async function loadAppConfig() {
  const response = await fetch(configFileUrl);
  const config = await response.json();

  window.appConfig = config;

  if (config.featureFlags.supportSchemePreferences) {
    document.documentElement.setAttribute("support-scheme-preferences", "");
  }
}

// This is a Promise<void> instead of a Promise<AppConfig> to encourage the intended usage pattern:
// await it during initial startup, then other code can confidently use the global variable.
window.appConfigLoaded = loadAppConfig();

const layout = document.getElementById("single-spa-layout");
const routes = constructRoutes(layout, { props: { configFileUrl } });
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
start({ urlRerouteOnly: false });
